import ky, { Options } from 'ky';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setAccessToken } from '../store/auth/actions';
import { getTokenExpiresIn } from '../utils';
import useOnLogout from '../library/hooks/user/useOnLogout';
import { UserProfileContext } from '../library/interfaces';

const { FLUZ_WEB_SERVER_URL, FLUZ_BASIC_AUTH_USERNAME, FLUZ_BASIC_AUTH_PASSWORD } = process.env;

export interface RefreshTokenResponse {
  accessToken?: string;
}

interface CommonRequestOptionsConfig {
  token?: string;
  useBasicAuth?: boolean;
  basicAuthUsername?: string;
  basicAuthPassword?: string;
}

let refreshingToken: Promise<unknown> | null = null;

export type ChangeSessionContextActionParams = {
  sessionContext: UserProfileContext;
  sessionContextId: string;
  userId: string;
  refreshToken: string;
};

export default function useCommonRequestOptions() {
  const { refreshToken, accessToken, isAuthenticated } = useSelector((state: RootStateOrAny) => state.auth);
  const dispatch = useDispatch();
  const onLogout = useOnLogout();
  /*eslint-disable @typescript-eslint/no-use-before-define */
  async function refreshAccessToken() {
    try {
      if (!refreshingToken) {
        refreshingToken = ky
          .post('token/refresh', { ...getCommonRequestOptions('auth'), json: { refreshToken } })
          .json()
          .then((tokenResponse) => {
            refreshingToken = null;
            return tokenResponse;
          });
      }
      const tokenResponse: RefreshTokenResponse = (await refreshingToken) as RefreshTokenResponse;

      if (tokenResponse.accessToken) {
        dispatch(setAccessToken(tokenResponse.accessToken));
        return tokenResponse.accessToken;
      } else {
        throw new Error('No access-token');
      }
    } catch (err) {
      refreshingToken = null;
      onLogout();
    }
  }

  // The `timeout` parameter is being used to increase the timeout limit from 50s to 90s. This may be needed with the increase in task limit increase per cycle 25 -> 100
  function getCommonRequestOptions(route: string, config?: CommonRequestOptionsConfig, timeout = true): Options {
    const baseUrl = FLUZ_WEB_SERVER_URL;
    const prefixUrl = `${baseUrl ? baseUrl : ''}/api/v1/${route}`;

    const headers = resolveHeaders(config);

    const commonRequestOptions: Options = {
      prefixUrl,
      headers,
      retry: { methods: ['get', 'post'], limit: 3, statusCodes: [408, 500, 502, 503, 504] },
      timeout: timeout ? 50000 : 90000,
      hooks: {
        afterResponse: [
          async (request, options, response) => {
            if (response.status === 498 && isAuthenticated) {
              const token = await safeGetValidAccessToken();
              request.headers.set('Authorization', `Bearer ${token}`);
              return ky(request);
            } else if (response.status === 401 && isAuthenticated) {
              onLogout();
            }
          },
        ],
      },
    };
    return commonRequestOptions;
  }

  function resolveHeaders(config?: CommonRequestOptionsConfig) {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };
    if (config) {
      const { token, useBasicAuth, basicAuthUsername, basicAuthPassword } = config;
      const usingAuthenticationScheme = token || useBasicAuth;
      if (usingAuthenticationScheme) {
        headers.Authorization = token
          ? `Bearer ${token}`
          : `Basic ${Buffer.from(
              `${basicAuthUsername ?? FLUZ_BASIC_AUTH_USERNAME}:${basicAuthPassword ?? FLUZ_BASIC_AUTH_PASSWORD}`
            ).toString('base64')}`;
      }
    }
    return headers;
  }

  async function safeGetValidAccessToken() {
    if (getTokenExpiresIn(accessToken, 's') > 30 && isAuthenticated) {
      return accessToken;
    } else {
      return await refreshAccessToken();
    }
  }

  return { getCommonRequestOptions, safeGetValidAccessToken };
}
