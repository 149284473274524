import { CSpinner } from '@coreui/react';
import React, { ReactElement } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Avatar from '../../components/Avatar';
import Label from '../../components/common/label/Label';
import useUserProfile from '../../library/hooks/user/useUserProfile';
import { displayCurrency } from '../../utils/payment/display-currency';
import { FraudStatus } from '../../library/enums';
import { useIntercom } from 'react-use-intercom';
import classNames from 'classnames';
import { UserState } from '../../store/user/reducer';

import ArrowDown from '../../assets/images/misc-icons/arrow-down.png';

type ProfileCardProps = {
  onClick?(): void;
  className?: string;
  variant?: 'default' | 'secondary';
};
export default function ProfileCard({
  onClick,
  className: wrapperClassName,
  variant = 'default',
}: ProfileCardProps): ReactElement {
  const { user, spendingLimits } = useSelector((state: RootStateOrAny) => state.user as UserState);
  const { loading } = useUserProfile();
  const { show: showIntercomMessenger } = useIntercom();

  const isBusinessAccount = user?.selected_account_id !== user?.account?.account_id;
  const businessAccount = user?.business_accounts?.find(
    (business_account: any) => business_account?.business?.account_id === user?.selected_account_id
  );

  function getTrustStatusDisplayLabel(trustStatus: FraudStatus) {
    if (trustStatus === 'PASS') {
      return (
        <p
          className={classNames('avatar-text-sub', {
            // 'd-flex flex-column align-items-end ': variant === 'secondary',
          })}
        >
          <span
            className={classNames({
              'd-none d-sm-inline': variant === 'default',
              // 'p-extra-small color-gray_1000 font-weight-bold': variant === 'secondary',
            })}
          >
            Available to spend:{' '}
          </span>
          <Label className="fluz-gradient-green ml-1" style={{ width: 'fit-content' }}>
            {displayCurrency(Number(Number(spendingLimits?.limit?.limit) - Number(spendingLimits?.daily_spent)))}
          </Label>
        </p>
      );
    } else {
      return (
        <p
          className="avatar-text-sub"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={showIntercomMessenger}
        >
          Contact support
          {['FAIL', 'LOCKED'].includes(trustStatus) ? (
            <Label className="label-error ml-1">{trustStatus}</Label>
          ) : trustStatus === 'PENDING_DOCUMENTS' ? (
            <Label className="label-info ml-1">Verification Required</Label>
          ) : (
            <Label className="label-warning ml-1">In Review</Label>
          )}
        </p>
      );
    }
  }

  const getTitle = () => {
    if (!isBusinessAccount) {
      return user?.email_address;
    } else {
      return businessAccount?.business?.business_name || '';
    }
  };

  const getAvatarProps = () => {
    if (isBusinessAccount) {
      return {
        firstName: businessAccount?.business?.business_name,
        lastName: '',
        uri: undefined,
      };
    }

    return {
      firstName: user?.first_name,
      lastName: user?.last_name,
      uri: user?.account?.account_avatar_url,
    };
  };

  if (loading) {
    return <CSpinner />;
  } else if (variant === 'default') {
    return (
      <div
        className={classNames(
          'd-flex',
          {
            'cursor-pointer': !!onClick,
          },
          wrapperClassName
        )}
        onClick={onClick}
      >
        <div>
          <p className="avatar-text-primary">{getTitle()}</p>
          {getTrustStatusDisplayLabel(user?.trust_status)}
        </div>

        <Avatar className="d-none d-sm-block ml-3" {...getAvatarProps()} />
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          'd-flex justify-content-end align-items-center',
          {
            'cursor-pointer': !!onClick,
          },
          wrapperClassName
        )}
        onClick={onClick}
      >
        <div className="d-flex align-items-center justify-content-end gap-2">
          <div className="d-flex flex-column align-items-end">
            <h2
              style={{
                fontWeight: 750,
                width: 'max-content',
                textAlign: 'end',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '215px',
              }}
            >
              {getTitle()}
            </h2>
            {getTrustStatusDisplayLabel(user?.trust_status)}
          </div>
          <Avatar className="d-sm-block" {...getAvatarProps()} />
          {(user?.business_accounts?.length || 0) > 0 && (
            <img alt="arrow-down" src={ArrowDown} style={{ width: 9.5, height: 5.5 }} />
          )}
        </div>
      </div>
    );
  }
}
