export const RolesAtCompany = ['Founder', 'CFO', 'Investor', 'Office Manager', 'Other'];
export const OtherRole = 'Other';

export const DescriptionsOfCompany = [
  'Registered with the SEC',
  'A publicly-traded company',
  'Majority owned by a public company',
  'An internet gambling business',
  'Dealing with controlled substances',
  'Involved in the sale distribution or manufacturing of firearms or ammunition',
  'A government organization',
  'Part of a tax anticipation program',
  'An adult entertainment businesss',
];

export const PowerPortalUsageOptions = [
  { label: 'Corporate gifting', key: 'CORPORATE_GIFTING' },
  { label: 'Corporate spending admin', key: 'CORPORATE_SPENDING_ADMIN' },
  { label: 'Maximize Rewards', key: 'REWARDS_MAXIMIZER' },
  { label: 'Gift Card Reselling', key: 'GIFT_CARD_RESELLING' },
  {
    label: 'Purchase Goods for Business Use (e.g. Contractors, Interior Design)',
    key: 'PURCHASE_GOODS_FOR_BUSINESS_USE',
  },
  { label: 'Online seller / retail purchasing', key: 'ONLINE_SELLER_RETAIL_PURCHASING' },
  { label: 'Other', key: 'OTHERS' },
];

export enum KybStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED_FOR_APPROVAL = 'SUBMITTED_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  REVIEW = 'REVIEW',
  FAILED = 'FAILED',
  PASS = 'PASS',
}

export enum BusinessIndustry {
  ACCOUNTING_AND_BOOKKEEPING = 'ACCOUNTING_AND_BOOKKEEPING',
  ADVERTISING = 'ADVERTISING',
  ART_AND_ENTERTAINMENT = 'ART_AND_ENTERTAINMENT',
  BEAUTY_AND_COSMETIC_SERVICES = 'BEAUTY_AND_COSMETIC_SERVICES',
  BIOTECH = 'BIOTECH',
  BUSINESS_MANAGEMENT = 'BUSINESS_MANAGEMENT',
  CLEANING_SERVICES = 'CLEANING_SERVICES',
  CONSTRUCTION = 'CONSTRUCTION',
  CONSULTING = 'CONSULTING',
  CRYPTO = 'CRYPTO',
  DESIGN = 'DESIGN',
  ECOMMERCE = 'ECOMMERCE',
  EDUCATION = 'EDUCATION',
  ENERGY = 'ENERGY',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  FITNESS = 'FITNESS',
  FOOD = 'FOOD',
  HARDWARE = 'HARDWARE',
  HEALTH_SERVICES = 'HEALTH_SERVICES',
  INSURANCE = 'INSURANCE',
  INVESTMENT = 'INVESTMENT',
  LEGAL = 'LEGAL',
  MARKETING = 'MARKETING',
  MARKETPLACE = 'MARKETPLACE',
  MINING = 'MINING',
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  REAL_ESTATE = 'REAL_ESTATE',
  RESEARCH = 'RESEARCH',
  RETAIL_AND_WHOLESALE = 'RETAIL_AND_WHOLESALE',
  SECURITY = 'SECURITY',
  SHOPPING_AND_WAREHOUSING = 'SHOPPING_AND_WAREHOUSING',
  SPORTS = 'SPORTS',
  SOFTWARE_AND_TECHNOLOGY = 'SOFTWARE_AND_TECHNOLOGY',
  TRANSPORTATION = 'TRANSPORTATION',
  TRAVEL = 'TRAVEL',
}

export enum BusinessStructure {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  LLC = 'LLC',
  CORPORATION = 'CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  COOP = 'COOP',
}

export const BusinessIndustryOptions = [
  { value: BusinessIndustry.ACCOUNTING_AND_BOOKKEEPING, label: 'Accounting & Bookkeeping' },
  { value: BusinessIndustry.ADVERTISING, label: 'Advertising' },
  { value: BusinessIndustry.ART_AND_ENTERTAINMENT, label: 'Art & Entertainment' },
  { value: BusinessIndustry.BEAUTY_AND_COSMETIC_SERVICES, label: 'Beauty & Cosmetic Services' },
  { value: BusinessIndustry.BIOTECH, label: 'Biotech' },
  { value: BusinessIndustry.BUSINESS_MANAGEMENT, label: 'Business Management' },
  { value: BusinessIndustry.CLEANING_SERVICES, label: 'Cleaning Services' },
  { value: BusinessIndustry.CONSTRUCTION, label: 'Construction' },
  { value: BusinessIndustry.CONSULTING, label: 'Consulting' },
  { value: BusinessIndustry.CRYPTO, label: 'Crypto' },
  { value: BusinessIndustry.DESIGN, label: 'Design' },
  { value: BusinessIndustry.ECOMMERCE, label: 'Ecommerce' },
  { value: BusinessIndustry.EDUCATION, label: 'Education' },
  { value: BusinessIndustry.ENERGY, label: 'Energy' },
  { value: BusinessIndustry.FINANCIAL_SERVICES, label: 'Financial Services' },
  { value: BusinessIndustry.FITNESS, label: 'Fitness' },
  { value: BusinessIndustry.FOOD, label: 'Food' },
  { value: BusinessIndustry.HARDWARE, label: 'Hardware' },
  { value: BusinessIndustry.HEALTH_SERVICES, label: 'Health Services' },
  { value: BusinessIndustry.INSURANCE, label: 'Insurance' },
  { value: BusinessIndustry.INVESTMENT, label: 'Investment' },
  { value: BusinessIndustry.LEGAL, label: 'Legal' },
  { value: BusinessIndustry.MARKETING, label: 'Marketing' },
  { value: BusinessIndustry.MARKETPLACE, label: 'Marketplace' },
  { value: BusinessIndustry.MINING, label: 'Mining' },
  { value: BusinessIndustry.PROFESSIONAL_SERVICES, label: 'Professional Services' },
  { value: BusinessIndustry.REAL_ESTATE, label: 'Real Estate' },
  { value: BusinessIndustry.RESEARCH, label: 'Research' },
  { value: BusinessIndustry.RETAIL_AND_WHOLESALE, label: 'Retail & Wholesale' },
  { value: BusinessIndustry.SECURITY, label: 'Security' },
  { value: BusinessIndustry.SHOPPING_AND_WAREHOUSING, label: 'Shopping & Warehousing' },
  { value: BusinessIndustry.SPORTS, label: 'Sports' },
  { value: BusinessIndustry.SOFTWARE_AND_TECHNOLOGY, label: 'Software & Technology' },
  { value: BusinessIndustry.TRANSPORTATION, label: 'Transportation' },
  { value: BusinessIndustry.TRAVEL, label: 'Travel' },
];

export const BusinessStructureOptions = [
  { value: BusinessStructure.COOP, label: 'Co-op' },
  { value: BusinessStructure.CORPORATION, label: 'Corporation' },
  { value: BusinessStructure.LLC, label: 'LLC' },
  { value: BusinessStructure.PARTNERSHIP, label: 'Partnership' },
  { value: BusinessStructure.SOLE_PROPRIETORSHIP, label: 'Sole proprietorship' },
];
