import { Seat } from './Seat';
import { KybStatus } from '../enums';
export interface Account {
  account_id: string;
  account_avatar_url: string;
  seats?: Seat[];
  enterprise_service_terms_accepted: boolean;
  has_exclusive_rate: boolean;
}
export interface AccountBalance {
  total_available_balance: number;
  available_rewards_balance: number;
  life_time_rewards_balance: number;
  available_cash_balance: number;
  life_time_cash_balance: number;
  unsettled_instant_cash_balance: number;
  pending_cash_balance: number;
  total_cash_balance: number;
  available_reserve_balance: number;
  available_gift_card_balance: number;
  life_time_gift_card_balance: number;
  unsettled_instant_gift_card_balance: number;
  pending_gift_card_balance: number;
  total_gift_card_balance: number;
}

export enum BusinessOwnershipType {
  BUSINESS_PRIMARY_OWNER = 'BUSINESS_PRIMARY_OWNER',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
}
export enum BusinessStructure {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  LLC = 'LLC',
  CORPORATION = 'CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  COOP = 'COOP',
}

export interface Business {
  business_id: string;
  business_name: string;
  dba_name: string;
  phone_number: string;
  established_at: string;
  website_url: string;
  business_structure: BusinessStructure;
  business_agent_name: string;
  state_of_incorporation: string;
  tax_id: string;
  ein_letter_url: string;
  incorporation_article_url: string;
  sole_proprietorship_document_url: string;
  other_supporting_documents_url: string;
  additional_documents_urls: string[];
  num_of_owners: number;
  kyb_status: KybStatus;
  kyb_reason_log_id?: string;
  account_id?: string;
  business_category_id?: string;
  business_sub_category_id?: string;
}

export interface BusinessAccount {
  business_account_id: string;
  business_id: string;
  user_id: string;
  business_user_id: string;
  ownership_percentage: number;
  owner_since: Date;
  title: string;
  type: BusinessOwnershipType;
  account_id: string;
  business?: Business;
}
