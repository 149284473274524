import { UserPrivacyMode, FraudStatus, KycStatus, AmlStatus } from '../enums';
import { Account, AccountBalance, BusinessAccount } from './Account';
import { Seat } from './Seat';
import { PreferredPaymentMethod } from '../interfaces';
export interface User {
  user_id: string;
  owned_account_id?: string;
  first_name: string;
  last_name: string;
  user_name: string;
  email_address: string;
  phone_number?: string;
  private_mode?: UserPrivacyMode;
  trust_status: FraudStatus;
  kyc_status: KycStatus;
  aml_status: AmlStatus;
  is_verified: boolean;
  date_of_birth: Date;
  lifetime_spent: number;
  has_pin_code: boolean;
  balance?: AccountBalance;
  account: Account;
  require_cip_verification: boolean;
  preferred_mcc_id?: string;
  is_cip_verified?: boolean;
  user_payment_preferences?: PreferredPaymentMethod[];
  business_accounts?: BusinessAccount[];
  user_profile?: UserProfile;
  selected_account_id: string;
}

export enum UserProfileContext {
  CONSUMER = 'CONSUMER',
  BUSINESS = 'BUSINESS',
}
export interface UserProfile {
  user_profile_id: string;
  session_context: UserProfileContext;
  session_context_id: string;
}

export interface UserSpendingLimit {
  limit: number;
  type: 'DAY' | 'MONTH';
}
export interface SpendingLimitResponse {
  limit: UserSpendingLimit;
  daily_spent: number;
  mtd_spent: number;
  ytd_spent: number;
}

export interface MerchantSpentResponse {
  merchant_spent_today: number;
  merchant_spent_this_week: number;
  merchant_spent_this_month: number;
}

export interface WalletEarningsResponse {
  total_available_balance: number;
  available_rewards_balance: number;
  life_time_rewards_balance: number;
  available_cash_balance: number;
  life_time_cash_balance: number;
  unsettled_instant_cash_balance: number;
  pending_cash_balance: number;
  personal_earnings: number;
  network_earnings: number;
  total_unsettled_instant_balance: number;
}

export interface PrepaidReserveBalanceResponse {
  current_reserve_balance: number;
  chargeback_repayment_due: number;
  user_reserve_minimum: number;
  total_amount_due: number;
}
export interface UserReserveBalanceSettingsResponse {
  has_min_reserve: boolean;
  user_reserve_minimum: number;
  accepted_terms_and_conditions: boolean;
}

export interface UserBalanceSettingsResponse {
  accepted_terms_and_conditions: boolean;
}

export interface UserProfileResponse {
  user: User;
  balance: AccountBalance;
  seats: Seat[];
  user_payment_preferences?: PreferredPaymentMethod[];
}
